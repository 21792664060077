import {Controller} from '@hotwired/stimulus'
import { Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export default class extends Controller {
  static values = { chartData: Array, options: Object, type: String }

  connect() {
   this.renderChart()
  }

  renderChart() {
    const ctx = this.element.getContext("2d")
    if (this.chart) this.chart.destroy()

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.chartDataValue.map(d => d[0]),
        datasets: [{
          data: this.chartDataValue.map(d => d[1]),
          backgroundColor: "rgba(1, 105, 115, 0.3)",
          borderColor: "rgba(1, 105, 115, 1)",
          borderWidth: 1,
          fill: true
        },
        {
          data: this.chartDataValue.map(d => d[2]),
          backgroundColor: "rgba(130, 130, 130, 0.1)",
          borderColor: "rgba(130,130,130,0.3",
          borderWidth: 1,
          fill: true
        }],
      },
      options: this.optionsValue || {}
    })
  }
}
