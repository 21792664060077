require('@rails/ujs').start()
import "@hotwired/turbo-rails";

require('trix')
require('@rails/actiontext')
require('fullcalendar/fullcalendar.js')

global.toastr = require('toastr')

import 'bootstrap'

import '../src/global'
import '../src/static_pages'
import '../src/groups'
import '../src/types'
import '../src/users'
import '../src/bookings'
import 'chartkick/chart.js'
import '../styles/styles'
import 'tom-select'
import '@fortawesome/fontawesome-free/css/all'

import '../controllers'

import flatpickr from 'flatpickr'
require('flatpickr/dist/flatpickr.css')
require('flatpickr/dist/themes/airbnb.css')
import { German } from 'flatpickr/dist/l10n/de.js'
